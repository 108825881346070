import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uniq from 'lodash/uniq';
import flow from 'lodash/flow';
import some from 'lodash/some';
import every from 'lodash/every';
import union from 'lodash/union';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import {PlainInput} from 'midoffice/components/IE9';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Button from 'midoffice/components/Button';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';
import {flattenTree, getTreeNodeByCode} from 'midoffice/helpers/permission';

import {injectField} from 'midoffice/newforms/decorators';

function hasChildren({children}) {
    return Array.isArray(children);
}

function getPermissionCodes(props) {
    if (!hasChildren(props)) {
        return [props.code];
    }
    return props.children.reduce(
        (acc, child) => [...acc, ...getPermissionCodes(child)],
        []
    );
}

export class Permission extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        children: PropTypes.array,
        code: PropTypes.string,
        isChecked: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        isDefault: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        isPartialChecked: PropTypes.bool.isRequired,
        onSelect: PropTypes.func.isRequired,
    };

    onClickHandle = ev => {
        this.props.onSelect(getPermissionCodes(this.props), ev.target.checked);
    };

    render() {
        const {
            title, code, children, description,
            onSelect,
            isDisabled, isDefault, isPartialChecked, isChecked,
            isVisible,
        } = this.props;

        const hasPermissionChildren = hasChildren(this.props);

        const permissionClassName = classnames('checkbox checkbox-rev', {
            'selection-pane__group-checkbox': hasPermissionChildren,
            'selection-pane__group-checkbox--partial': isPartialChecked,
        });

        if (!isVisible) {
            return null;
        }

        return (<div>
            <div className={permissionClassName}>
                <label className={classnames({
                    'control--disabled': isDisabled,
                })}>
                    <input type="checkbox" name={code || title} disabled={isDisabled} checked={isChecked} onChange={this.onClickHandle}/>
                    <span className="checkbox__icon" />
                    {title} {isDefault && ('• ')} {isDefault && (<strong className="text-gray">Role Default</strong>)}
                    {!isChecked && isDisabled && ('• ')} {!isChecked && isDisabled && (<em className="text-gray">Unavailable</em>)}
                    {description && (<AnnotateTooltip id="on-request">
                        <Glyphicon glyph={'info-sign'} />
                        {description}
                    </AnnotateTooltip>)}
                </label>
            </div>
            {hasPermissionChildren && (
                <div className="selection-pane__section__group">
                    {children.map(child => <Permission
                        {...child}
                        key={child.code || child.title}
                        onSelect={onSelect}
                    />)}
                </div>
            ) }
        </div>);
    }
}

const parseFiltersToArray = filters => Object.entries(filters).map(([name, item]) => ({...item, name})) ;
const getDefaultFilterValues = filters =>
    parseFiltersToArray(filters)
        .reduce((acc, {name, defaultValue}) => ({...acc, [name]: defaultValue}), {});

@injectField
export default class GranularPermissions extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.array,
        onChange: PropTypes.func.isRequired,
        tree: PropTypes.array,
        defaultChoices: PropTypes.array,
        disabledChoices: PropTypes.array,
        hiddenChoices: PropTypes.array,
        resetText: PropTypes.string,
        errors: PropTypes.string,
        onSetFilterValue: PropTypes.func,
        collapsing: PropTypes.bool.isRequired,
        filters: PropTypes.objectOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                processor: PropTypes.func.isRequired,
                defaultValue: PropTypes.bool,
            })
        ),
        skipPreviousValue: PropTypes.bool,
    };

    static defaultProps = {
        defaultChoices: [],
        disabledChoices: [],
        hiddenChoices: [],
        collapsing: true,
        filters: {},
        skipPreviousValue: false,
    };

    state = {
        open: false,
        showAvailable: true,
        showUnavailable: false,
        showGrantedEditable: true,
        showGrantedNonEditable: true,
        filter: '',
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.onSetFilterValue && this.state.filter !== prevState.filter) {
            const tree = this.parseTree(this.props.tree);
            this.props.onSetFilterValue(this.getVisibleChoices(tree));
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.tree !== state.tree) {
            return {
                ...state,
                tree: props.tree,
                getItemByCode: getTreeNodeByCode(props.tree),
                filters: getDefaultFilterValues(props.filters),
            };
        }

        return state;
    }


    getVisibleChoices = values => {
        const allCodes = flattenTree(this.props.tree).map(({code}) => code);
        return values.length > 0 ? values.filter(value => value.isVisible).map(({code}) => code) : allCodes;
    };

    isQuickFiltered({code, title, parentTitle}) {
        function check(code, string) {
            return code.toLowerCase().indexOf(string.toLowerCase()) > -1;
        }
        const {filter} = this.state;
        return !filter || check(parentTitle, filter) || check(code, filter) || check(title, filter);
    }

    isFiltered = (item) => {
        const filterArray = parseFiltersToArray(this.props.filters);
        return isEmpty(filterArray) || filterArray.reduce(
            (acc, {name, processor}) => acc || this.state.filters[name] && processor(item),
            false,
        );
    }

    isChildHidden = ({code}) => {
        const {hiddenChoices} = this.props;
        return hiddenChoices.includes(code);
    }

    isChildVisible = (item) => {
        return !this.isChildHidden(item)
            && this.isQuickFiltered(item)
            && this.isFiltered(item);
    }

    isParentVisible({children}) {
        return some(children, ({isVisible}) => isVisible);
    }

    isChildChecked({code}) {
        const {defaultChoices, value} = this.props;
        const selectedCodes = difference(value, defaultChoices);
        return selectedCodes.includes(code) || defaultChoices.includes(code);
    }

    isParentChecked({children}) {
        return some(children, ({isDisabled}) => !isDisabled)
            ? every(children, ({isChecked, isDisabled}) => isDisabled || isChecked)
            : every(children, ({isChecked}) => isChecked);
    }

    isParentPartialChecked({children, isChecked}) {
        return !isChecked && some(
            children,
            ({isPartialChecked, isChecked}) => isPartialChecked || isChecked
        );
    }

    isChildDefault({code}) {
        const {defaultChoices} = this.props;
        return defaultChoices.includes(code);
    }

    isParentDefault({children}) {
        return every(children, ({isDefault}) => isDefault);
    }

    isChildDisabled({code}) {
        const {defaultChoices, disabledChoices} = this.props;
        return disabledChoices.includes(code) || defaultChoices.includes(code);
    }

    isParentDisabled({children}) {
        return every(children, ({isDisabled}) => isDisabled);
    }

    toggleOpening = () => this.setState(
        ({open}) => ({
            open: !open,
        })
    );

    parseTree = (items, parentTitle = '') => {
        const getNextParentTitle = item => [parentTitle, item.title].join(' ');
        return items.map(item => hasChildren(item)

            ? this.parseParent({
                ...item,
                children: this.parseTree(item.children, getNextParentTitle(item))
            })

            : this.parseChild({...item, parentTitle})
        );
    }

    parseParent = flow(
        item => ({
            ...item,
            isDisabled: this.isParentDisabled(item),
            isDefault: this.isParentDefault(item),
            isChecked: this.isParentChecked(item),
        }),
        item => ({...item, isPartialChecked: this.isParentPartialChecked(item)}),
        item => ({...item, isVisible: this.isParentVisible(item)}),
    );

    parseChild = flow(
        (item) => ({
            ...item,
            isDisabled: this.isChildDisabled(item),
            isDefault: this.isChildDefault(item),
            isPartialChecked: false,
            isChecked: this.isChildChecked(item),
        }),
        item => ({...item, isVisible: this.isChildVisible(item)}),
    );

    onSelectHandle = (codes, selected) => {
        const {defaultChoices, disabledChoices, value, skipPreviousValue, onChange} = this.props;
        const bindersAttr = selected ? 'dependencies' : 'dependants';
        const mapCodesWithBinders = codes => {
            const liftedCodes = Array.isArray(codes) ? codes : [codes];
            return liftedCodes.reduce(
                (acc, code) => [
                    ...acc,
                    code,
                    ...flow(
                        this.state.getItemByCode,
                        item => item[bindersAttr] || [],
                        binders => mapCodesWithBinders(binders),
                    )(code)
                ],
                [],
            );
        };

        const filteredCodes = difference(codes, union(defaultChoices, disabledChoices));
        flow(
            mapCodesWithBinders,
            binders => binders.filter(binder => !filteredCodes.includes(binder)),
            bindedCodes => selected
                ? difference(uniq([...(skipPreviousValue ? [] : value), ...filteredCodes, ...bindedCodes]), defaultChoices)
                : value.filter(val => ![...filteredCodes, ...bindedCodes].includes(val)),
            onChange,
        )(filteredCodes);
    };

    handleReset = ev => {
        ev.preventDefault();
        // Is is right, check later / why we need filter value by reset?
        this.props.onChange(
            this.props.value.filter(item => this.props.disabledChoices.indexOf(item) > -1)
        );
        this.setState({
            filters: getDefaultFilterValues(this.props.filters),
        });
    };

    handleShowCheckbox = ev => {
        const {name, checked} = ev.target;
        const filters = {...this.state.filters, [name]: checked};
        this.setState({filters});
    }

    handleFilterInput = ev => {
        this.setState({filter: ev.target.value});
    };
    //TODO: Replace <input type="checkbox" name={name} /> with Checkbox component
    renderFilter() {
        const {filters} = this.props;
        const filterArray = parseFiltersToArray(filters);
        return (<div className="selection-pane__filters">
            {filterArray.length > 0 && (<div className="selection-pane__filters__control">
                <div className="selection-pane__filters__title">
                    <strong>Show:</strong>
                </div>
                {parseFiltersToArray(filters).map(({name, label}) => (
                    <div className="checkbox checkbox-rev" key={`filter-${name}`}>
                        <label>
                            <input type="checkbox" name={name} checked={this.state.filters[name]} onChange={this.handleShowCheckbox} />
                            <span className="checkbox__icon" />
                            {label}
                        </label>
                    </div>
                ))}
            </div>)}

            <header className="selection-pane__header">
                <div className="selection-pane__form">
                    <div className="selection-pane__form__input">
                        <PlainInput type="text" name="granular_permission_filter" className="form-control" placeholder="Quick Filter" value={this.state.filter} onChange={this.handleFilterInput} />
                    </div>
                </div>
            </header>
        </div>);
    }


    renderPermissionsTree() {
        const {tree} = this.props;
        return (<div className="selection-pane__section">
            {this.parseTree(tree).map(item => <Permission {...item}
                key={item.code || item.title}
                onSelect={this.onSelectHandle}
            />)}
        </div>);
    }

    renderDetails() {
        const {value, defaultChoices} = this.props;
        const extraPermissions = value.filter(item => !defaultChoices.includes(item));
        return extraPermissions.length > 0
            ? (
                <aside className="row__toggle__label__aside">
                    <strong>{extraPermissions.length} extra permission{extraPermissions.length === 1 ? '' : 's'}</strong> granted
                </aside>
            ) : (
                <aside className="row__toggle__label__aside">No additional permissions granted</aside>
            );
    }

    renderFooter() {
        const {resetText} = this.props;
        if (!resetText) {
            return null;
        }
        return (<footer className="selection-pane__footer">
            <Button bsStyle="link" className={'highlight-red btn-link--narrow'}
                name="reset_assigned" onClick={this.handleReset}
            >
                {resetText}
            </Button>
        </footer>);
    }

    render() {
        const {open} = this.state;
        const {collapsing, errors} = this.props;
        const wrapperClassName = classnames('row__toggle row__toggle--form-group', {
            'row__toggle--expanded': open || !collapsing,
        });
        const paneWrapperCx = classnames('selection-pane__wrapper', {
            'selection-pane__wrapper--error': errors
        });

        return (
            <div className={wrapperClassName}>
                {collapsing && (<div className="row__toggle__label" data-name="open_granular_permissions" onClick={this.toggleOpening}>
                    <Glyphicon bsClass={'glyphicons'} glyph={'chevron-right'} />
                    Edit additional permissions
                    {!open && this.renderDetails()}
                </div>)}
                <div className="row__toggle__content">
                    <div className={paneWrapperCx}>
                        {this.renderFilter()}
                        {this.renderPermissionsTree()}
                        {this.renderFooter()}
                    </div>
                </div>
            </div>
        );
    }
}
